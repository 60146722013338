<template>
  <v-card id="tool-tip"
    elevation="5"
    v-if="isOpen"
    :style="tooltipStyle"
  >
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      style: {
        position: "absolute",
        top: 0,
        left: 0
      },
      styleTouch: {
        position: "absolute",
        top: 0,
        left: 0
      }
    };
  },
  computed: {
    tooltipStyle() {
      if (window.innerWidth < 769) {
        return this.styleTouch;
      } else {
        return this.style;
      }
    }
  },
  created() {
    //ポインター移動イベントの登録
    const listenerFunction = e => this.tipAxisForPC(e);
    window.addEventListener("mousemove", listenerFunction, {passive: true});

    //イベントリスナーの破棄を設定しておく
    this.$once("hook:beforeDestroy", function() {
      window.removeEventListener("mousemove", listenerFunction);
    });
  },
  methods: {
    //幅769px以上のデバイス用
    tipAxisForPC(e) {
      this.style.top = parseInt(e.clientY) + -20 + "px";
      this.style.left = parseInt(e.clientX) + -220 + "px";
    },
    //タッチデバイス（幅769px未満）用
    tipAxisForTouch(e) {
      this.styleTouch.top = parseInt(e.changedTouches[0].pageY) - 90 + "px";
      this.styleTouch.left = parseInt(e.changedTouches[0].pageX) - 100 + "px";
    }
  }
};
</script>

<style scoped>
#tool-tip {
  z-index: 15;
  padding: 1em;
  background-color: #ffffff;
  border-radius: 3px;
  font-size: 0.8em;
  text-align: center;
}
.theme--dark #tool-tip {
  background-color: var(--content-bg-dark-diff);
}
</style>
