<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="600"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="py-5 px-10">
          <v-card-title>
            <h2>{{ formTitle }}</h2>
          </v-card-title>

          <v-form ref="form">
            <v-card-text>以下の内容がお客様のLINEに送信されます。</v-card-text>
            <v-row>
              <v-col>
                <v-card class="preview pa-3"
                  max-height="300"
                >
                  <pre id="push-message"><span v-html="messageTitle"></span>

    &#x1f478; {{ eventData.extendedProps.cast_name }}さん
    &#x1f552; {{ startStr }} からのご予約
    &#x1f4a1; 店舗から{{ cancel }}のメッセージ：『

{{ notificationMessage }}

    』

*お問い合わせは直接「スタッフLINE」までお願いします。</pre>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="notificationMessage"
                  filled
                  min-height="170"
                  counter="1000"
                  label="メッセージを入力してください"
                  :rules="[valiRules.max1000]"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                @click="formSubmitted"
              >
                {{ formButton }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-form>

          <!-- ローダー -->
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute top
            color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from 'moment'
import $literals from '@/literals.js'
import { ValidationRules } from '@/module.js'

export default {
  props: {
    updateEventInfo: {
      type: Object,
      default: () => ({})
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  computed: {
    formTitle() {
      return this.formType === 'confirm' ? '予約確定とメッセージの送信' : '予約キャンセルとお詫びメッセージの送信'
    },
    formButton() {
      return this.formType === 'confirm' ? '確定' : '送信'
    },
    isConfirm() {
      return this.formType === 'confirm' ? true : false
    },
    cancel() {
      return this.formType === 'confirm' ? '' : 'お詫び'
    }
  },

  data() {
    return {
      eventData: {},
      messageTitle: null,
      startStr: null,
      notificationMessage: null,
      formType: null,
      submitCallback: null,
      openForm: false,
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
    }
  },

  methods: {
    open(handdownData) {
      this.formType = handdownData.formType
      this.submitCallback = handdownData.submitCallback
      this.eventData = handdownData.eventData

      if (this.isConfirm) {
        this.messageTitle = '&#x1f389 お客様のご予約が店舗にて確定されたのでお知らせいたします &#x1f389'
      } else {
        this.messageTitle = '大変申し訳ありませんが今回のご予約はキャンセルとなりました &#x1f647'
      }

      this.startStr = moment(this.eventData.start).format('MM月DD日 HH:mm')

      this.openForm = true
    },

    close() {
      this.notificationMessage = ''
      this.submitCallback = null
      this.openForm = false
    },

    formSubmitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      let title, className, bookingStatus

      if (this.isConfirm) {
        title = this.eventData.extendedProps.booking_type + '予約：' + this.eventData.extendedProps.course_name
        className = this.eventData.classNames.filter( val => val !== 'tentative' )
        bookingStatus = '確定'
      } else {
        title = this.eventData.extendedProps.booking_type + '予約：' + 'キャンセル'
        className = ['canceled']
        bookingStatus = 'キャンセル'
      }

      const exProp = this.eventData.extendedProps
      const formData = new FormData()
      formData.append('shop_id', this.shopData.shop_id)
      formData.append('customer_id', exProp.customer_id)
      formData.append('cast_id', exProp.cast_id)
      formData.append('booking_type', exProp.booking_type)
      formData.append('booking_status', bookingStatus)
      formData.append('start_at', moment(this.eventData.start).format('YYYY-MM-DD HH:mm'))
      formData.append('end_at', moment(this.eventData.end).format('YYYY-MM-DD HH:mm'))
      formData.append('course_id', exProp.course_id)
      formData.append('is_honshi', exProp.is_honshi)
      formData.append('place', exProp.place)
      formData.append('note', exProp.note)
      formData.append('booker_name', exProp.booker_name)
      formData.append('booker_phone', exProp.booker_phone)

      const updateData = {
        id: this.eventData.id,
        customer_id: exProp.customer_id,
        title: title,
        classNames: className,
        booking_status: bookingStatus,
        message: document.querySelector('#push-message').innerText
      }

      if (this.submitCallback) {
        this.submitCallback(formData, updateData)
      }
      this.close()
    }
  }
}
</script>

<style scoped>
.preview {
  overflow-y: scroll;
  background-color: var(--content-bg-diff);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.preview::-webkit-scrollbar {
  display: none;
}
.theme--dark .preview {
  background-color: var(--content-bg-dark-diff)
}
.preview pre {
  white-space: break-spaces;
}
</style>
