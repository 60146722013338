<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="400"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="py-5 px-10">
          <v-card-title class="mb-5">
            <h2>{{ formTitle }}</h2>
          </v-card-title>

          <v-form ref="form">
            <v-row>
              <v-col>
                <v-select
                  class="mx-2"
                  v-model="shiftData.castId"
                  label="キャスト"
                  :items="shopData.castArray"
                  item-value="cast_id"
                  item-text="name"
                  item-color="primary"
                  :rules="[valiRules.required]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-menu top
                  v-model="openPickerIn"
                  :close-on-content-click="false"
                  :nudge-left="60"
                  transition="scroll-y-reverse-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="shiftData.in"
                      label="出勤開始"
                      prepend-icon="mdi-clock-start"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[valiRules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="shiftData.in"
                    format="24hr"
                    :allowed-hours="allowedHours"
                    :allowed-minutes="allowedMins"
                    header-color="primary"
                    color="primary"
                    @click:minute="openPickerIn = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu top
                  v-model="openPickerOut"
                  :close-on-content-click="false"
                  :nudge-left="60"
                  transition="scroll-y-reverse-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="shiftData.out"
                      label="受付終了"
                      prepend-icon="mdi-clock-end"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[valiRules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="shiftData.out"
                    format="24hr"
                    :allowed-hours="allowedHours"
                    :allowed-minutes="allowedMins"
                    header-color="primary"
                    color="primary"
                    @click:minute="openPickerOut = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                :disabled="!isFormChanged"
                color="primary"
                @click="formSubmitted"
              >
                {{ formButton }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-form>

          <!-- ローダー -->
          <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute top
          color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import moment from "moment";
import $literals from "@/literals.js";
import { ADMIN_SCHEDULE } from "@/literals.js";
import { BizHour, ValidationRules } from '@/module.js'

export default {
  props: {
    shopData: {
      type: Object,
      required: true
    },
    shiftEvents: {
      type: Array,
      required: true
    },
    inputSlotInfo: {
      type: Object
    },
    updateEvent: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      shiftData: {id: '', in: '', out: '', castId: 0},
      openPickerIn: false,
      openPickerOut: false,
      allowedHours: [],
      allowedMins: [],
      openForm: false,
      loading: false,
      isFormChanged: false,
      formType: null,
      snackbar: {open: false, color: 'primary', message: ''},
      valiRules: ValidationRules,
      bizHour: new BizHour(this.shopData)
    };
  },

  computed: {
    formTitle() {
      return this.formType === "create" ? "出勤登録" : "出勤更新";
    },
    formButton() {
      return this.formType === "create" ? "登録" : "更新";
    },
    isCreate() {
      return this.formType === "create" ? true : false;
    },
    castName() {
      return castId => {
        const castData = this.shopData.castArray.find( cast => cast.cast_id == castId )
        return castData !== undefined ? castData.name : ''
      }
    }
  },

  watch: {
    shiftData: {
      handler: function(now) {
        if (!this.isCreate) {
          if (now.castId === this.updateEvent.cast_id && now.in === this.updateEvent.start && now.out === this.updateEvent.end) {
            return
          }
        }
        this.isFormChanged = true
      },
      deep: true
    },
  },

  created() {
    this.allowedHours = this.bizHour.getBizHourArray()
    this.allowedMins = this.bizHour.getBizMinArray()
  },

  methods: {
    open(formType) {
      this.formType = formType;

      if (this.isCreate) {
        this.shiftData.castId = this.inputSlotInfo.castInfo.cast_id;
        this.shiftData.shiftData = moment(this.bizHour.getBizOpening(this.inputSlotInfo.start.dateTime)).format("YYYY-MM-DD");
        this.shiftData.in = moment(this.inputSlotInfo.start.dateTime).format('HH:mm');
        this.shiftData.out = moment(this.inputSlotInfo.end.dateTime).format('HH:mm');
      } else {
        this.shiftData.castId = this.updateEvent.cast_id;
        this.shiftData.id = this.updateEvent.id;
        this.shiftData.shiftData = moment(this.bizHour.getBizOpening(this.updateEvent.start)).format("YYYY-MM-DD")
        this.shiftData.in = moment(this.updateEvent.start).format('HH:mm');
        this.shiftData.out = moment(this.updateEvent.end).format('HH:mm');
      }
      this.openForm = true;
    },

    close() {
      this.openPickerIn = false
      this.openPickerOut = false
      this.isFormChanged = false;
      this.openForm = false;
    },

    formSubmitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      //バリデーション：すでに出勤登録済みか
      if (this.isCreate) {
        const castShift = this.shiftEvents.find( shift => shift.cast_id === this.shiftData.castId );
        if (castShift) {
          this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFcCastAlreadyShiftIn, open: true}}
          return;
        }
      }

      //バリデーション：InOut時刻の論理性
      const inH = Number(this.shiftData.in.split(':')[0])
      const outH = Number(this.shiftData.out.split(':')[0])

      if (inH == outH ||
          (inH < outH && inH <= this.bizHour.closingHourNum && outH >= this.bizHour.openingHourNum) ||
          (inH > outH &&
            ((inH >= this.bizHour.openingHourNum && outH >= this.bizHour.openingHourNum) ||
            (inH <= this.bizHour.closingHourNum && outH <= this.bizHour.closingHourNum))
          )
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationStartEndTime, open: true}}
        return;
      }

      //時刻の設定
      const inDT = moment(this.shiftData.shiftData +' '+ this.shiftData.in)
      const outDT = moment(this.shiftData.shiftData +' '+ this.shiftData.out)
      const openingDT = this.bizHour.getBizOpening(this.shiftData.shiftData)
      const closingDT = this.bizHour.getBizClosing(this.shiftData.shiftData)

      //日またぎ営業店考慮（24時以降なら1日追加）
      if (this.bizHour.closingHourNum <= this.bizHour.openingHourNum && inH < this.bizHour.openingHourNum) {
        inDT.add(1, "d");
      }

      if (this.bizHour.closingHourNum <= this.bizHour.openingHourNum && outH < this.bizHour.openingHourNum) {
        outDT.add(1, "d");
      }

      //バリデーション：出勤時間外
      if (inDT.isBefore(openingDT) || inDT.isSameOrAfter(closingDT) ||
          outDT.isSameOrBefore(openingDT) || outDT.isAfter(closingDT)
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFcShiftOutOfBizHour, open: true}}
        return;
      }

      //バリデーション：出勤時間足らず
      if (outDT.diff(inDT, "minutes") < ADMIN_SCHEDULE.fcalMinimumShiftMins) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationShiftHoursTooShort, open: true}}
        return;
      }

      const shiftData = {
        id: this.shiftData.id,
        cast_id: this.shiftData.castId,
        shift_date: this.shiftData.shiftData,
        start: inDT.format('YYYY-MM-DD HH:mm'),
        end: outDT.format('YYYY-MM-DD HH:mm'),
        cast_name: this.castName(this.shiftData.castId),
        title: "出勤",
        resourceId: this.shiftData.castId,
        event_type: "shift",
        classNames: "shift-in",
        start_time: this.shiftData.in,
        end_time: this.shiftData.out
      };

      if (this.isCreate) {
        this.$emit("register-submitted", shiftData);
      } else {
        this.$emit("update-submitted", shiftData);
      }
      this.close();
    },
  }
};
</script>

<style scoped>
>>> .v-time-picker-title {
  justify-content: center;
}
</style>
