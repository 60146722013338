<template>
  <v-container fluid class="content-wrap pa-0">
    <!-- コンテンツヘッダー -->
    <info-bar
      :btns="[
        {label:'', icon:'face-woman-outline', tip:'出勤キャストのみ表示', event:'displayRescWithEvents'},
        {label:'', icon:'sort', tip:'キャストの並び替え', event:'sortResources'},
        {label:'', icon:'calendar', tip:'日付を変更', event:'openPicker'},
      ]"
      @sortResources="toggleResourceSort"
      @displayRescWithEvents="toggleResourcesWithEvents"
      @openPicker="openDatePicker = !openDatePicker"
    >
      <template v-slot:content-info>
        <span class="mr-2 font-weight-bold">{{ titleDate }}</span>出勤：{{ shiftEvents.length }}名 ／ 予約：{{ bookingCount }}件
      </template>
    </info-bar>

    <fullcalendar ref="FCal" :options="calOpt">
      <!-- イベント用 content injection -->
      <template v-slot:eventContent="arg">
        <v-popover
          placement="bottom"
          offset="5"
          hideOnTargetClick
          :data-eventid="arg.event.id"
        >
          <div class="event-info">
            <span class="booking-title">{{ arg.event.title }}</span>
          </div>
          <!-- FCイベントポップオーバー -->
          <template v-slot:popover>
            <v-card class="event-info-pop">
              <a class="event-pop-close" v-close-popover>
                <v-icon>mdi-close-circle-outline</v-icon>
              </a>

              <div>
                <h3 class="mr-3">{{ arg.event.extendedProps.cast_name }}</h3>
                <p>{{ arg.event.extendedProps.start_time }} ~ {{ arg.event.extendedProps.end_time }}</p>
              </div>

              <div class="booking-status-btns"
                v-if="arg.event.extendedProps.booking_status === '仮予約' &&
                      (arg.event.extendedProps.booking_type === 'LINE' || arg.event.extendedProps.booking_type === 'ネット')"
              >
                <v-btn class="primary"
                  small
                  @click="openFormBookingStatus('confirm', arg.event)"
                >予約確定</v-btn>
                <v-btn
                  small outlined
                  @click="openFormBookingStatus('cancel', arg.event)"
                >ｷｬﾝｾﾙ</v-btn>
              </div>

              <v-card-text
                class="event-info-pop-detail"
                v-if="arg.event.extendedProps.event_type === 'booking'"
              >
                <p>
                  本指名：
                  <v-icon v-if="arg.event.extendedProps.is_honshi">mdi-checkbox-marked-circle</v-icon>
                  <v-icon v-else small>mdi-close-thick</v-icon>
                </p>
                <p>
                  コース：{{ arg.event.extendedProps.course_type }}／{{ arg.event.extendedProps.course_name }}
                </p>
                <p>
                  予約名：{{ arg.event.extendedProps.booker_name }} 様
                </p>
                <p v-if="arg.event.extendedProps.customer_id">
                  会員番号：{{ zeroAddedNo(arg.event.extendedProps.customer_id) }}
                </p>
                <p v-if="arg.event.extendedProps.booker_phone">
                  電話番号：{{ arg.event.extendedProps.booker_phone }}
                </p>
                <p v-if="arg.event.extendedProps.place">
                  場所：{{ arg.event.extendedProps.place }}
                </p>
                <p v-if="arg.event.extendedProps.note">
                  備考：{{ arg.event.extendedProps.note }}
                </p>
              </v-card-text>
              <v-card-text
                class="event-info-pop-detail"
                v-if="arg.event.extendedProps.event_type === 'shift'"
              >
                <p>出勤</p>
              </v-card-text>

              <div class="event-info-pop-btns">
                <v-btn class="primary"
                  small
                  @click="openFormRegister(arg.event.extendedProps.event_type)"
                >更新</v-btn>
                <v-btn
                  v-if="arg.event.extendedProps.event_type === 'booking'"
                  small text
                  @click="routeToCustomerInfo(arg.event)"
                >
                  <v-icon>mdi-face-agent</v-icon>
                </v-btn>
                <v-btn
                  small text
                  @click="openModalDelete(arg.event)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-card>
          </template>
        </v-popover>
      </template>
    </fullcalendar>

    <!-- デートピッカー -->
    <v-date-picker
      v-if="openDatePicker"
      v-model="pickedDete"
      locale="ja"
      no-title
      elevation="5"
      :day-format="date => numericDate(date)"
      event-color="accent"
      @input="changeDisplayDate"
    ></v-date-picker>

    <!-- 出勤登録フォーム -->
    <form-shift-register
      ref="refFormShiftRegister"
      :shopData="shopData"
      :shiftEvents="shiftEvents"
      :updateEvent="touchedEvent"
      :inputSlotInfo="inputSlotInfo"
      @register-submitted="createShiftRecord($event)"
      @update-submitted="updateShiftRecord($event)"
      @reset="$emit('reset')"
    ></form-shift-register>

    <!-- 予約登録フォーム -->
    <form-booking-register
      ref="refFormBookingRegister"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
      :shiftEvents="shiftEvents"
      :updateEvent="touchedEvent"
      :inputSlotInfo="inputSlotInfo"
      @register-submitted="createBookingRecord($event)"
      @update-submitted="updateBookingRecord($event)"
      @reset="$emit('reset')"
    ></form-booking-register>

    <!-- 予約ステータス変更フォーム -->
    <form-booking-status
      ref="formBookingStatus"
      :shopData="shopData"
    ></form-booking-status>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm"
      @modal-confirmed="$refs.modalConfirm.close()"
    >
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- 削除モーダル -->
    <modal-delete ref="modalDelete"
      @modal-confirmed="$refs.modalDelete.close()"
    >
      <div v-html="modalMessage"></div>
    </modal-delete>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- イベント移動中ツールチップ -->
    <tooltip :isOpen="isEventMoving">
      <p>{{ touchedEvent.cast_name }} {{ touchedEvent.title }}</p>
      <p>{{ touchedEvent.start_time }} ~ {{ touchedEvent.end_time }}</p>
    </tooltip>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import 'moment/locale/ja'
import { VPopover, VClosePopover } from 'v-tooltip'
import fullcalendar from '@fullcalendar/vue'
import jaLocale from '@fullcalendar/core/locales/ja'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
// import scrollGridPlugin from '@fullcalendar/scrollgrid'
// import timeGridPlugin from '@fullcalendar/timegrid'
// import dayGridPlugin from '@fullcalendar/daygrid'
// import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'

import $literals from '@/literals.js'
import { ADMIN_SCHEDULE } from '@/literals.js'
import { CheckTokenError, ApiTool, BizHour } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ContentInfoBar from '@/components/_ContentInfoBar.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import ModalDelete from '@/components/_ModalDelete.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import ToolTip from '@/components/_ToolTip.vue'
import FormShiftRegister from '@/components/ScheduleFormShift.vue'
import FormBookingRegister from '@/components/ScheduleFormBooking.vue'
import FormBookingStatus from '@/components/ScheduleFormBookingStatus.vue'

//***************************************************
//
//グローバル
//
//***************************************************
let FCalApi
const DISPLAY_RANGE = ADMIN_SCHEDULE.fcalDisplayRange
const MINIMUM_SHIFT_HOURS = ADMIN_SCHEDULE.fcalMinimumShiftMins

export default {
  components: {
    fullcalendar,
    tooltip: ToolTip,
    'v-popover': VPopover,
    'loader': Loader,
    'info-bar': ContentInfoBar,
    'modal-confirm': ModalConfirm,
    'modal-delete': ModalDelete,
    'overlay-message': OverlayMessage,
    'form-shift-register': FormShiftRegister,
    'form-booking-register': FormBookingRegister,
    'form-booking-status': FormBookingStatus
  },

  directives: {
    'close-popover': VClosePopover
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      //**************************
      //カレンダーオプション
      //**************************
      calOpt: {
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',

        //プラグイン一覧
        plugins: [
          interactionPlugin,
          // scrollGridPlugin,
          resourceTimelinePlugin,
          // timeGridPlugin,
          // dayGridPlugin,
          // resourceTimeGridPlugin
        ],
        headerToolbar: false,
        titleFormat: { month: 'numeric', day: 'numeric', weekday: 'short' },
        locale: jaLocale,
        initialView: 'TimelineDay',
        resources: null,
        events: null,

        //**************************
        //ビュー別設定
        //**************************
        views: {
          //横タイムラインの設定
          TimelineDay: {
            type: 'resourceTimeline',
            duration: { days: DISPLAY_RANGE },
            slotMinWidth: 20,
            // buttonText: 'Ⅲ',
            resourceAreaWidth: '180px',
            resourceAreaColumns: [{headerClassNames: 'cast-header', headerContent: 'キャスト(出勤管理順)'}],
            slotLabelFormat: [
              // {month: 'numeric', day: 'numeric', weekday: 'short'},
              { hour: '2-digit', minute: '2-digit' }
            ]
          },
          //縦グリッド表示の設定（googleカレンダー的）
          TimeGridDay: {
            type: 'resourceTimeGrid',
            duration: { days: DISPLAY_RANGE },
            // buttonText: 'Ⅲ',
            dayMinWidth: 60,
            datesAboveResources: true,
            slotEventOverlap: false,
            // slotLabelInterval: '01:00',
            slotLabelFormat: { hour: '2-digit', minute: '2-digit' }
          }
        },

        //**************************
        //共通設定
        //**************************
        resourceOrder: '-shift_order, is_dummy, -cast_order', //デフォルト＝出勤台帳のシフトオーダー
        filterResourcesWithEvents: false,
        dayHeaderFormat: { month: 'numeric', day: 'numeric', weekday: 'short' },
        businessHours: {
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          startTime: '10:00', //ダミー（createdで設定）
          endTime: '02:00' //ダミー（createdで設定）
        },
        slotMinTime: '09:00', //ダミー（createdで設定）
        slotMaxTime: '25:00', //ダミー（createdで設定）
        //nextDayThreshold: this.shopData.CLOSING_HOUR, //終日イベント用なので関係なし
        slotDuration: '00:15',
        defaultTimedEventDuration: '01:00',
        forceEventDuration: true,
        // rerenderDelay: 500,
        scrollTime: '12:00', //ダミー（createdで設定）
        initialDate: new Date(), //ダミー
        nowIndicator: true,
        // allDaySlot: false, //終日イベント表示
        expandRows: true,
        editable: true,
        droppable: false,
        selectable: true,
        eventResizableFromStart: true,
        eventResourceEditable: false,
        unselectCancel: '#modal-form',
        selectMirror: false, //
        dayMaxEvents: true, //
        longPressDelay: 150, //タッチデバイス用

        //**************************
        //イベントフック
        //**************************
        viewDidMount: this.afterViewMount, //メインビューマウント完了
        datesSet: this.handleDatesSet, //日付切替時
        resourceLabelDidMount: this.afterResourceInit, //リソースマウント完了
        // eventContent: , Content InjectionはVue templateで設定
        eventSourceSuccess: this.afterEventFetch, //イベントフェッチ完了
        eventDidMount: this.afterEventMount,
        eventClassNames: this.addEventClass,
        loading: this.handleFCloading, //フェッチと完了時に発生
        slotLaneClassNames: this.handleLaneClass,
        select: this.handleDateSelect,
        // dateClick: this.handleDateClick, //selectフックに含まれる
        eventClick: this.handleEventClick,
        eventResize: this.handleEventMove,
        eventChange: this.handleEventChange,
        eventDragStart: this.handleEventDragStart,
        eventDragStop: this.handleEventDragStop,
        eventDrop: this.handleEventMove
        // eventAdd: this.handleEventAdd,
        // eventRemove: this.handleEventRemove,
        // eventsSet: this.handleEvents,
        // eventMouseEnter: this.handleEventMouseEnter,
        // eventMouseLeave: this.handleEventMouseLeave,
      },

      //**************************
      //データ
      //**************************
      //フォームに渡すタイムスロット情報格納
      inputSlotInfo: {
        castInfo: {},
        start: {},
        end: {},
        course: {}
      },
      //更新/削除クリックしたイベント情報格納用
      touchedEvent: {
        id: null,
        title: null,
        start: null,
        end: null
      },
      //その他
      shiftEvents: [], //スロットに表示中の出勤情報配列
      bookingCount: 0,
      resourceCount: 0,
      firstResource: null,
      headerTitleLabel: '',
      titleDate: '', //タイトルに表示中の日付
      displayDates: [], //表示中の日付情報
      fetchEventsInterval: 60000,
      fetchEventsTimer: null,
      isEventMoving: false,
      pickedDete: '',
      openDatePicker: false,
      poLoader: { loading: false, top: 0, left: 0 },
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      bizHour: new BizHour(this.shopData)
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    numericDate() {
      return date => moment(date).format('D')
    },
    zeroAddedNo() {
      return no => ('000' + no).slice(-4)
    },
  },

  //***************************************************
  //ルートガード
  //***************************************************
  beforeRouteUpdate(to, from, next) {
    FCalApi.gotoDate(this.pickedDete);

    //並び替え様にリソースを再取得
    FCalApi.refetchResources();

    next()
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.adminApi.setToken(this.serverToken)
    this.loadingMessage = 'スケジュール帳初期化・・・'

    //営業時間
    this.calOpt.businessHours.startTime = this.bizHour.openingHour
    this.calOpt.businessHours.endTime = this.bizHour.closingHour24

    //FCスロット開始＆終了時刻
    this.calOpt.slotMinTime = this.bizHour.slotOpenHHMM
    this.calOpt.slotMaxTime = this.bizHour.slotCloseHHMM

    //初期表示日付と時刻の設定
    if (this.$route.query.date && moment(this.$route.query.date).format() !== 'Invalid date') {
      this.setInitialDate(this.$route.query.date)
    } else {
      this.setInitialDate()
    }
    this.setScrollTime()

    //リソースソースの設定
    this.calOpt.resources = this.getResources

    //イベントソースの設定
    const vm = this
    this.calOpt.events = {
      events: function(fetchInfo, successCallback, failureCallback) {
        vm.getEvents(fetchInfo, successCallback, failureCallback)
      },
      id: 1 //getEventSourceById用
    }

    //リソースラベルのContent injection設定
    this.calOpt.resourceLabelContent = this.resourceContentInjection
    this.calOpt.resourceLabelClassNames = this.resourceClassNameInputs

    //フォームに必要なデータを取得
    Promise.all([
      this.adminApi.getReqWithAuth('cast/').then( results => {
        if (!results || !results.length) {
        this.modalMessage = '<p>キャストが一人も登録されていません。<br />「店舗管理」→「キャスト管理」から登録してください。</p>'
        this.$refs.overlayMessage.open()
        return
        }
        this.shopData.castArray = [...results]
       }),
      this.adminApi.getReqWithAuth('course-type/').then( results => {
        if (!results || !results.length) return
        this.shopData.courseTypeArray = [...results]
       }),
      this.adminApi.getReqWithAuth('course/').then( results => {
        if (!results || !results.length) return
        this.shopData.courseArray = [...results]
       }),
      this.adminApi.getReqWithAuth('sales-ad/').then( results => {
        if (!results || !results.length) return
        this.shopData.salesAdArray = [...results]
       }),
    ])
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })

    //店舗営業時間に合わせた時刻＆分配列セット
    this.shopData.bizHourArray = this.bizHour.getBizHourArray()
    this.shopData.bizMinArray = this.bizHour.getBizMinArray()
  },

  mounted() {
    //カレンダーオブジェクトへのアクセス可能化
    FCalApi = this.$refs.FCal.getApi()

    FCalApi.setOption('height', window.innerHeight - 47)

    if (this.shopData.system_plan_id < 2) {
      this.modalMessage = $literals.MESSAGE.availableForPaidPlan
      this.$refs.overlayMessage.open()
      return
    }

    //規定アイドル分後にイベント再フェッチのハンドラーを登録
    window.addEventListener('mousemove', this.fetchEventsOnInterval, {passive: true})

    //イベントハンドラーの破棄を設定しておく
    this.$once('hook:beforeDestroy', function() {
      window.removeEventListener('mousemove', this.fetchEventsOnInterval)
      clearTimeout(this.fetchEventsTimer)
    })
  },

  //***************************************************
  //***************************************************
  //メソッド
  //***************************************************
  //***************************************************
  methods: {
    //
    //（イベントハンドラー）規定アイドル分数後にイベント再フェッチ
    //
    fetchEventsOnInterval() {
      clearTimeout(this.fetchEventsTimer)

      this.fetchEventsTimer = setInterval(function() {
        FCalApi.getEventSourceById(1).refetch();
      }, this.fetchEventsInterval);
    },

    //
    //初期表示日付の設定
    //
    setInitialDate(date) {
      if (!date) {
        date = new Date();
        //日またぎ営業店考慮
        if (this.bizHour.closingHourNum <= this.bizHour.openingHourNum && date.getHours() <= this.bizHour.closingHourNum) {
          date.setDate(date.getDate() - 1);
        }
      }
      this.pickedDete = moment(date).format('YYYY-MM-DD')
      this.calOpt.initialDate = this.pickedDete
    },

    //
    //初期表示時刻
    //
    setScrollTime() {
      const now = new Date();
      let initTime = now.getHours();

      if (this.bizHour.closingHourNum <= this.bizHour.openingHourNum) {
        if (now.getHours() < this.bizHour.closingHourNum) {
          initTime += 24;
        }
      }
      this.calOpt.scrollTime = initTime - 2 + ':00';
    },

    //
    //リソースヘッダーのClassName Inputs設定
    //
    resourceClassNameInputs(resourceObj) {
      const classArray = [
        'resource-label-injected',
        'resource-' + resourceObj.resource.id
      ];
      // if (resourceObj.resource.extendedProps.is_dummy) classArray.push('resource-dummy-cast')
      return classArray;
    },

    //
    //リソースヘッダーのContent Injection設定
    //
    resourceContentInjection(resourceObj) {
      const cast = resourceObj.resource.extendedProps;
      const el = document.createElement('div');
      const castName = document.createElement('span');
      const castImg = document.createElement('img');
      const btns = document.createElement('div');
      const sokuBtn = document.createElement('button');
      const dummyIcon = document.createElement('span');
      const shiftInput = document.createElement('input');

      castName.innerHTML = cast.cast_name;
      castName.classList.add('cast-name');

      castImg.src = cast.image_url;

      // shiftInput.onclick = this.shiftFormOpen
      shiftInput.classList.add('input-shift');
      shiftInput.setAttribute('type', 'text');
      shiftInput.setAttribute('wrap', 'soft');
      shiftInput.dataset.id = cast.cast_id;
      shiftInput.value = '出勤なし';

      sokuBtn.onclick = this.toggleIsSoku;
      sokuBtn.classList.add('btn-material-design');
      sokuBtn.dataset.id = cast.cast_id;
      sokuBtn.innerHTML = '即ひめ';
      if (cast.is_soku) {
        sokuBtn.classList.add('soku-btn', 'soku-on');
      } else {
        sokuBtn.classList.add('soku-btn', 'soku-off');
      }

      if (cast.is_dummy) dummyIcon.classList.add('icon-dummy-cast')

      btns.classList.add('resource-bottons');
      btns.appendChild(sokuBtn);
      btns.appendChild(dummyIcon);

      el.appendChild(dummyIcon);
      el.appendChild(castImg);
      el.appendChild(btns);
      el.appendChild(castName);
      el.appendChild(shiftInput);
      return { domNodes: [el] };
    },

    //デートピッカーで日付変更のコールバック
    changeDisplayDate() {
      if (this.pickedDete === this.$route.query.date) return

      this.openDatePicker = false;
      this.$router.push({query: { date: this.pickedDete }})

      // FCalApi.gotoDate(this.pickedDete);
      //
      // //並び替え様にリソースを再取得
      // FCalApi.refetchResources();
      //
      // this.openDatePicker = false;
    },

    //******************************************************************************************************
    //
    //                    APIコール
    //
    //******************************************************************************************************
    //
    //キャストデータの取得（＝FCリソースソース、引数はFC規定）
    //
    getResources(fetchInfo, successCallback, failureCallback) {
      this.loadingMessage = 'キャストデータ取得中・・・';

      const apiPartial = 'schedule/resource/' + moment(this.bizHour.getBizOpening(this.displayDates[0] || new Date())).format('YYYY-MM-DD')

      return this.adminApi.getReqWithAuth(apiPartial).then(response => {
        successCallback(response || []);
      })
      .catch(error => {
        failureCallback(error);
        if (CheckTokenError(error)) this.$emit('reset')
      })
    },

    //
    //出勤＆予約データの取得（＝FCイベンドソース、引数はFC規定）
    //
    getEvents(fetchInfo, successCallback, failureCallback) {
      this.loadingMessage = 'スケジュールデータ取得中・・・';

      const fromDate = moment(fetchInfo.start).format('YYYY-MM-DD') + ' ' + this.bizHour.openingHour;
      const toDate = moment(this.bizHour.getBizClosing(fromDate)).add(DISPLAY_RANGE - 1, 'd').format('YYYY-MM-DD HH:mm');

      const apiPartial = 'schedule/event/' + fromDate + '/to/' + toDate

      return this.adminApi.getReqWithAuth(apiPartial).then(response => {
        successCallback(response || []);
      })
      .catch(error => {
        failureCallback(error);
        if (CheckTokenError(error)) this.$emit('reset')
      })
    },

    //***************************************************
    //新規出勤登録
    //***************************************************
    createShiftRecord(shiftData) {
      this.loading = true;
      this.loadingMessage = '出勤データ登録中・・・';
      FCalApi.unselect();

      const apiPartial = 'shift/create/cast/' + shiftData.cast_id
      const data = {shift_date: shiftData.shift_date, start_at: shiftData.start, end_at: shiftData.end}

      this.adminApi.apiReqWithData('POST', apiPartial, JSON.stringify(data)).then( response => {
        shiftData.id = response.id;
        //イベントソースを追加(=true)しないとrefetchで結びつかないので二重に出てくる
        FCalApi.addEvent({ ...shiftData }, true);

        //出勤情報配列を更新
        this.shiftEvents.push({...shiftData});

        //リソースタイトルの出勤時刻を入力
        const elResource = document.querySelector('.resource-' + shiftData.cast_id + ' .input-shift');
        elResource.value = shiftData.start_time + '~' + shiftData.end_time;

        // const titleEl = document.querySelector('.fc-header-toolbar .event-count');
        // this.headerTitleLabel = '出勤:' + this.shiftEvents.length + '名／予約:' + this.bookingCount + '件';

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false );
    },

    //***************************************************
    //新規予約登録
    //***************************************************
    createBookingRecord(bookingData) {
      this.loading = true;
      this.loadingMessage = '予約データ登録中・・・';
      FCalApi.unselect();

      const formData = new FormData();
      formData.append('shop_id', this.shopData.shop_id);
      formData.append('customer_id', bookingData.customer_id);
      formData.append('cast_id', bookingData.cast_id);
      formData.append('booking_type', bookingData.booking_type);
      formData.append('booking_status', bookingData.booking_status);
      formData.append('start_at', bookingData.start);
      formData.append('end_at', bookingData.end);
      formData.append('course_id', bookingData.course_id);
      formData.append('is_honshi', bookingData.is_honshi);
      formData.append('place', bookingData.place);
      formData.append('note', bookingData.note);
      formData.append('booker_name', bookingData.booker_name);
      formData.append('booker_phone', bookingData.booker_phone);

      const apiPartial = 'booking/create/'

      this.adminApi.apiReqWithData('POST', apiPartial, formData).then( response => {
        bookingData.id = response.id;
        FCalApi.addEvent({...bookingData}, true);

        if (bookingData.booking_type !== 'ダミー') ++this.bookingCount

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false );
    },

    //***************************************************
    //出勤更新
    //***************************************************
    updateShiftRecord(shiftData) {
      this.loading = true;
      this.loadingMessage = '出勤データ更新中・・・';

      const apiPartial = 'shift/update/cast/' + shiftData.cast_id + '/' + shiftData.id
      const data = {start_at: shiftData.start, end_at: shiftData.end}

      this.adminApi.apiReqWithData('PUT', apiPartial, JSON.stringify(data)).then(() => {
        const targetEvent = FCalApi.getEventById(shiftData.id);

        targetEvent.setDates(shiftData.start, shiftData.end);
        targetEvent.setExtendedProp('start_time', shiftData.start_time);
        targetEvent.setExtendedProp('end_time', shiftData.end_time);

        //出勤情報配列を更新
        let targetIndex = this.shiftEvents.findIndex( shiftInfo => shiftInfo.cast_id == shiftData.cast_id );
        if (targetIndex != -1) this.shiftEvents.splice(targetIndex, 1, shiftData);

        //リソースタイトルの出勤時刻
        const elResource = document.querySelector( '.resource-' + shiftData.cast_id + ' .input-shift' );
        elResource.value = shiftData.start_time + '~' + shiftData.end_time;

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false );
    },

    //***************************************************
    //予約更新
    //***************************************************
    updateBookingRecord(bookingData) {
      this.loading = true;
      this.loadingMessage = '予約データ更新中・・・';

      const formData = new FormData();
      formData.append('shop_id', this.shopData.shop_id);
      formData.append('customer_id', bookingData.customer_id);
      formData.append('cast_id', bookingData.cast_id);
      formData.append('booking_type', bookingData.booking_type);
      formData.append('booking_status', bookingData.booking_status);
      formData.append('start_at', bookingData.start);
      formData.append('end_at', bookingData.end);
      formData.append('course_id', bookingData.course_id);
      formData.append('is_honshi', bookingData.is_honshi);
      formData.append('place', bookingData.place);
      formData.append('note', bookingData.note);
      formData.append('booker_name', bookingData.booker_name);
      formData.append('booker_phone', bookingData.booker_phone);

      const apiPartial = 'booking/update/' + bookingData.id

      this.adminApi.apiReqWithData('PUT', apiPartial, formData).then(() => {
        const targetEvent = FCalApi.getEventById(bookingData.id);

        targetEvent.setProp('durationEditable', true); //setDatesの前に解除してイベント長を変更可に
        targetEvent.setProp('title', bookingData.title);
        targetEvent.setDates(bookingData.start, bookingData.end);
        targetEvent.setExtendedProp('event_type', bookingData.event_type);
        targetEvent.setExtendedProp('customer_id', bookingData.customer_id);
        targetEvent.setExtendedProp('cast_id', bookingData.cast_id);
        targetEvent.setExtendedProp('cast_name', bookingData.cast_name);
        targetEvent.setExtendedProp('booking_type', bookingData.booking_type);
        targetEvent.setExtendedProp('booking_status', bookingData.booking_status);
        targetEvent.setExtendedProp('course_id', bookingData.course_id);
        targetEvent.setExtendedProp('course_name', bookingData.course_name);
        targetEvent.setExtendedProp('is_honshi', bookingData.is_honshi);
        targetEvent.setExtendedProp('place', bookingData.place);
        targetEvent.setExtendedProp('note', bookingData.note);
        targetEvent.setExtendedProp('booker_name', bookingData.booker_name);
        targetEvent.setExtendedProp('booker_phone', bookingData.booker_phone);
        targetEvent.setExtendedProp('start_time', bookingData.start_time);
        targetEvent.setExtendedProp('end_time', bookingData.end_time);
        targetEvent.setProp('durationEditable', false);
        if (bookingData.classNames) targetEvent.setProp('classNames', bookingData.classNames);
        if (bookingData.resourceId) targetEvent.setResources([bookingData.resourceId]);

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false );
    },

    //***************************************************
    //出勤＆予約削除
    //***************************************************
    deleteRecord(fcEvent) {
      this.loading = true;
      this.loadingMessage = 'データ削除中・・・'

      let apiPartial

      if (fcEvent.extendedProps.event_type === 'shift') {
        apiPartial = 'shift/delete/cast/' + fcEvent.extendedProps.cast_id + '/' + fcEvent.id;
      } else {
        apiPartial = 'booking/delete/' + fcEvent.id;
      }

      this.adminApi.apiReqWithData('DELETE', apiPartial).then(() => {
        const targetEvent = FCalApi.getEventById(fcEvent.id);
        targetEvent.remove();

        if (fcEvent.extendedProps.event_type === 'shift') {
          //出勤情報配列も更新（フィルター削除）
          this.shiftEvents = this.shiftEvents.filter( shiftInfo => shiftInfo.id !== fcEvent.id );

          //リソースタイトルの出勤時刻
          const elResource = document.querySelector('.resource-' + fcEvent.extendedProps.cast_id + ' .input-shift');
          elResource.value = '出勤なし';
        } else {
          --this.bookingCount;
        }

        //タイトルの件数アップデート
        // const titleEl = document.querySelector( '.fc-header-toolbar .event-count' );
        // this.headerTitleLabel = '出勤:' + this.shiftEvents.length + '名／予約:' + this.bookingCount + '件';

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false );
    },

    //
    //予約イベントのステータス更新＆LINE通知
    //
    updateBookingState(formData, updateData) {
      this.loading = true;
      this.loadingMessage =
      updateData.booking_status === '確定' ? '予約確定処理中・・・' : '予約キャンセル処理中・・・';

      const apiPartial = 'booking/update/' + updateData.id

      this.adminApi.apiReqWithData('PUT', apiPartial, formData).then(() => {
          const targetEvent = FCalApi.getEventById(updateData.id);

          targetEvent.setProp('title', updateData.title);
          targetEvent.setProp('classNames', updateData.classNames);
          targetEvent.setExtendedProp('booking_status', updateData.booking_status);

          //LINE送信
          const apiPartial = 'customer/' + updateData.customer_id + '/pushmessage/'
          const payload = JSON.stringify({ message: updateData.message })

          this.adminApi.apiReqWithData('POST', apiPartial, payload).then( response => {
            this.snackbar = {...{color:'success', message: response.customer + '様へのメッセージを' + $literals.MESSAGE.successSendSubmit, open: true}}
          })
          .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
        })
        .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
        .then(() => this.loading = false );
    },

    //
    //即フラグをトグル
    //
    toggleIsSoku(e) {
      const el = e.currentTarget;
      const value = el.classList.contains('soku-on') ? false : true;

      el.classList.add('loading');

      const apiPartial = 'cast/update/' + e.currentTarget.dataset.id + '/is_soku'
      const payload = JSON.stringify({ 'value': value })

      this.adminApi.apiReqWithData('PUT', apiPartial, payload).then(() => {
        el.classList.toggle('soku-on');
        el.classList.toggle('soku-off');

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => el.classList.remove('loading') )
    },

    //******************************************************************************************************
    //
    //FCフック用コールバック
    //
    //******************************************************************************************************
    //
    //カレンダービューマウント後のコールバック
    //
    afterViewMount(info) {
      if (info.view.type === 'TimeGridDay') {
        const timegridBody = document.querySelector('.fc .fc-timegrid-body');
        const columnWidth = document.querySelector('.fc .fc-timegrid-col').offsetWidth;
        timegridBody.style.minWidth = columnWidth * this.resourceCount + 'px';
      }
    },

    //
    //表示日付決定後のコールバック
    //
    handleDatesSet(dateInfo) {
      const start = moment(dateInfo.start);
      const end = moment(dateInfo.end).startOf('day');

      this.displayDates.length = 0;

      //表示中日付の配列をメモリ格納
      while (start.isBefore(end)) {
        let date = moment(start).format('YYYY-MM-DD');
        this.displayDates.push(this.bizHour.getBizOpening(date));
        start.add(1, 'd');
      }

      this.titleDate = moment(dateInfo.start).format('MM月DD日(dd)');
    },

    //
    //イベントフェッチ後のコールバック（イベント毎）
    //
    afterEventFetch(rawRecord) {
      this.shiftEvents.length = 0;
      this.bookingCount = 0;

      //出勤イベントのメモリ格納
      rawRecord.forEach(event => {
        if (event.event_type === 'shift') {
          this.shiftEvents.push(event);

          setTimeout(function() {
            // リソースタイトルの出勤時刻を入力
            const elResource = document.querySelector('.resource-' + event.resourceId + ' .input-shift');
            if (elResource) elResource.value = event.start_time + '~' + event.end_time;
          }, 0);
        }
        else if (event.event_type === 'booking' && event.booking_type !== 'ダミー') {
          this.bookingCount++;
        }
      });
    },

    //
    //イベント＆リソースのloading＆完了時のコールバック
    //
    handleFCloading(isLoading) {
      if (isLoading) {
        this.loading = true;

        const resourceEls = document.querySelectorAll('.resource-timeline, .resource-grid');
        this.resourceCount = resourceEls.length;

        //リソースヘッダーのクラスリセット
        resourceEls.forEach(el => {
          el.classList.remove('in-cast');
          el.classList.remove('booked');
        });
      }
      //以下はリソースマウント後でイベントマウント前に発生
      else {
        this.loading = false;

        //リソースヘッダーに画像を入れためレーン高さがずれたので再レンダー
        if (FCalApi.view.type === 'TimelineDay') {
          setTimeout(function() { FCalApi.updateSize() }, 1000);
        }
      }
    },

    //
    //リソースマウント後のコールバック（リソース毎）
    //
    afterResourceInit(info) {
      //一番目のキャストをキャッシュ
      if (!this.firstResource) {
        this.firstResource = info.resource.id;
      }
      //キャスト名にクラス付与
      const castClass = 'cast-id-' + info.resource.id;
      info.el.classList.add(castClass);

      //現在のビュータイプでリソースヘッダー部のクラスを変更
      if (FCalApi.view.type === 'TimelineDay') {
        info.el.classList.add('resource-timeline');
      } else if (FCalApi.view.type === 'TimeGridDay') {
        info.el.classList.add('resource-grid');
      }
    },

    //
    //レンダリング時のイベントDOMクラス設定用コールバック
    //
    addEventClass(info) {
      const classArray = ['custom-event'];

      //ビュータイプによって付加クラスを別ける
      switch (info.view.type) {
        case 'TimelineDay':
          classArray.push('event-timeline');
          if (info.event.startStr === info.event.endStr) classArray.push('end-unset');
          break;

        case 'TimeGridDay':
          classArray.push('event-grid');
          if (info.event.startStr === info.event.endStr) classArray.push('end-unset');
          break;
      }
      return classArray;
    },

    //
    //イベントDOMマウント後のコールバック
    //
    afterEventMount(info) {
      //出勤キャストのリソースにクラス設定
      if (info.view.type === 'TimelineDay' || info.view.type === 'TimeGridDay') {
        const resourceEl = document.querySelector('.fc-resource.cast-id-' + info.event.extendedProps.cast_id);

        if (info.event.extendedProps.event_type === 'shift') {
          resourceEl.classList.add('in-cast');
        } else {
          if (!resourceEl.classList.contains('booked'))
            resourceEl.classList.add('booked');
        }
      }
    },

    //
    //スロットレーンのクラス付与（css border設定用）
    //
    handleLaneClass(info) {
      const hour = info.date.getHours();
      const min = info.date.getMinutes();
      const classArray = [];

      if (hour === 0 || hour === 12 || hour === 18) classArray.push('lane-sixth-hour')
      else if (hour === 3 || hour === 15 || hour === 21) classArray.push('lane-third-hour')

      if (min === 0) classArray.push('lane-oclock')
      else if (min === 15) classArray.push('lane-quarter-past')
      else if (min === 30) classArray.push('lane-half')
      else if (min === 45) classArray.push('lane-quarter-to')

      return classArray;
    },

    //******************************************************************************************************
    //
    //ユーザー発火イベント用コールバック
    //
    //******************************************************************************************************
    //
    //タイムスロット選択時（＝クリックイベント含む）
    //
    handleDateSelect(info) {
      const slotStart = moment(info.start);
      const slotEnd = moment(info.end);
      const slotDuration = (slotEnd - slotStart) / (1000 * 60);
      const bizDate = moment(this.bizHour.getBizOpening(slotStart)).format('YYYY-MM-DD');

      //バリデーション：スロット開始が営業時間外
      if (!this.bizHour.isInBizHours(slotStart, bizDate)) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFcSlotStartOutOfBizHour, open: true}}
        return;
      }

      //フォームで使用する情報をセット
      this.inputSlotInfo.start.dateTime = slotStart.format();
      this.inputSlotInfo.end.dateTime = slotEnd.format();
      this.inputSlotInfo.castInfo = { ...info.resource.extendedProps };

      //対象キャストの出勤有無を確認
      const targetShift = this.shiftEvents.find( shift => {
        return (  shift.cast_id === info.resource.extendedProps.cast_id &&
                  moment(shift.start).isSameOrAfter(this.bizHour.getBizOpening(slotStart)) &&
                  moment(shift.start).isSameOrBefore(this.bizHour.getBizClosing(slotStart))
        );
      });

      if (targetShift === undefined) {
        //バリデーション：出勤終了が営業時間外
        if (!this.bizHour.isInBizHours(slotEnd, bizDate)) {
          this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFcShiftOutOfBizHour, open: true}}
          return;
        }

        //出勤用の最小時間
        if (slotDuration < MINIMUM_SHIFT_HOURS) slotEnd.add(MINIMUM_SHIFT_HOURS - slotDuration, 'm');

        //終了時刻のセット
        this.inputSlotInfo.end.dateTime = slotEnd.format();

        //出勤登録フォーム
        this.$refs.refFormShiftRegister.open('create');
      } else {
        //バリデーション：予約開始が出勤時間外
        if (slotStart.isBefore(targetShift.start) || slotStart.isAfter(targetShift.end)) {
          this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFcBookingOutOfShiftHour, open: true}}
          return;
        }

        //予約用のコース情報
        this.inputSlotInfo.courseType = this.shopData.courseTypeArray[0]

        const chosenCourse = this.shopData.courseArray.find( course => course.course_mins === slotDuration );

        if (chosenCourse === undefined) {
          this.inputSlotInfo.course = this.shopData.courseArray[0]
        } else {
          this.inputSlotInfo.course = chosenCourse
        }

        //予約登録フォーム
        this.$refs.refFormBookingRegister.open('create');
      }

      FCalApi.unselect();
    },

    //***************************************************
    //イベントクリック時（＊イベント詳細表示はポップオーバーで）
    //***************************************************
    handleEventClick(info) {
      //クリックされたイベント情報のメモリ格納
      this.touchedEvent = {
        id: info.event.id,
        title: info.event.title,
        start: moment(info.event.start).format('YYYY-MM-DD HH:mm'),
        end: moment(info.event.end).format('YYYY-MM-DD HH:mm'),
        ...JSON.parse(JSON.stringify(info.event.extendedProps))
      };

      //イベント移動中のツールチップクローズ
      this.isEventMoving = false;
    },

    //
    //イベント詳細内の更新ボタンクリック時
    //
    openFormRegister(eventType) {
      if (eventType === 'shift') {
        this.$refs.refFormShiftRegister.open('update');
      } else {
        this.$refs.refFormBookingRegister.open('update');
      }
    },

    //
    //イベント詳細内の削除ボタンクリック時
    //
    openModalDelete(fcEvent) {
      if (fcEvent.extendedProps.event_type === 'shift') {
        const bookings = FCalApi.getEvents().find(event => {
          return (  event.extendedProps.event_type === 'booking' &&
                    fcEvent.extendedProps.cast_id === event.extendedProps.cast_id &&
                    moment(event.start).isSameOrAfter(fcEvent.start) &&
                    moment(event.start).isSameOrBefore(fcEvent.end)
          );
        });

        //予約がある出勤イベントの削除はNG
        if (bookings) {
          this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFcUnableDeleteShiftWithBooking, open: true}}
          return;
        }
      }

      const eventName = fcEvent.extendedProps.event_type === 'shift' ? '出勤情報' : '予約情報';

      //モーダルスロット用
      this.modalMessage = '<p class="mb-5">以下の情報を削除してよろしいですか？<p>' +
        '<p>「' + fcEvent.extendedProps.cast_name + '」の' + eventName + '</p>' +
        '<p>時間：' + fcEvent.extendedProps.start_time + '〜' + fcEvent.extendedProps.end_time + '</p>';

      const modalHanddown = {
        submitCallback: this.deleteRecord,
        comeBack: fcEvent
      };
      this.$refs.modalDelete.open(modalHanddown);
    },

    //***************************************************
    //イベント情報変更時（＝スロット移動ドロップ + バウンダリーチェンジ）
    //***************************************************
    handleEventMove(info) {
      const oldE = info.oldEvent;
      const newE = info.event;

      this.touchedEvent = {
        id: newE.id,
        title: newE.title,
        start: moment(newE.start).format('YYYY-MM-DD HH:mm'),
        end: moment(newE.end).format('YYYY-MM-DD HH:mm'),
        ...JSON.parse(JSON.stringify(newE.extendedProps))
      };

      //バリデーション：移動のリバート条件
      if (this.touchedEvent.event_type === 'booking') {
        //対象キャストの同営業日の出勤情報を取得
        const targetShift = this.shiftEvents.find(shiftInfo => {
          return (  shiftInfo.cast_id === this.touchedEvent.cast_id &&
                    moment(shiftInfo.start).isSameOrAfter(this.bizHour.getBizOpening(newE.start)) &&
                    moment(shiftInfo.start).isBefore(this.bizHour.getBizClosing(newE.start))
          );
        });
        //営業時間外
        if (targetShift === undefined || moment(newE.start).isAfter(this.bizHour.getBizClosing(newE.start))) {
          info.revert();
          return;
        }
        //出勤外
        if (moment(newE.start).isBefore(moment(targetShift.start)) || moment(newE.start).isAfter(moment(targetShift.end))) {
          info.revert();
          return;
        }
      } else if (this.touchedEvent.event_type === 'shift') {
        //出勤＆終了時刻が営業時間外なら
        if (moment(newE.start).isBefore(this.bizHour.getBizOpening(newE.start)) || moment(newE.end).isAfter(this.bizHour.getBizClosing(newE.start))) {
          info.revert();
          return;
        }
      }

      //イベントを移動 or startをドラッグ
      let message;
      if (oldE.start.getTime() !== newE.start.getTime()) message = '時刻を変更してよいですか？'
      else if (oldE.end.getTime() !== newE.end.getTime()) message = '終了時刻を変更してよいですか？';
      else return //移動なし

      //付随情報の更新
      this.touchedEvent.start_time = moment(newE.start).format('HH:mm');
      this.touchedEvent.end_time = moment(newE.end).format('HH:mm');

      //予約イベントの Drationが変わってるならコース情報変更
      if (this.touchedEvent.event_type === 'booking' &&
          ((moment(newE.end).diff(newE.start) - moment(oldE.end).diff(oldE.start)) / (1000 * 60)) !== 0
      ) {
        const newEventMins = moment(newE.end).diff(moment(newE.start)) / (1000 * 60);
        const typeCourses = this.shopData.courseArray.filter( course => course.course_type_id = this.touchedEvent.course_type_id )

        //同コースタイプ内に該当分数のコースが無い場合は既定コース外
        let newCourse = typeCourses.find( course => course.course_mins === newEventMins );
        if (newCourse === undefined) newCourse = this.shopData.courseArray[0];

        this.touchedEvent.course_id = newCourse.course_id;
        this.touchedEvent.course_name = newCourse.course_name;
        this.touchedEvent.title = this.touchedEvent.booking_type + '予約：' + newCourse.course_name;
      }

      //モーダルスロット用
      this.modalMessage = '<h3 class="mb-5">' + message + '</h3>' +
        `<p class="mb-2">「${this.touchedEvent.cast_name}」${this.touchedEvent.title}</p>` +
        '<p>開始：' + moment(oldE.start).format('HH:mm') + ' → ' + moment(newE.start).format('HH:mm') + '</p>' +
        '<p>終了：' + moment(oldE.end).format('HH:mm') + ' → ' + moment(newE.end).format('HH:mm') + '</p>';

      const modalHanddown = {
        buttonLabel: '変更',
        yesCallback: this.touchedEvent.event_type === 'shift' ? this.updateShiftRecord : this.updateBookingRecord,
        noCallback: info.revert,
        comeBack: this.touchedEvent
      };
      this.$refs.modalConfirm.open(modalHanddown);
    },

    //
    //イベントドラッグスタート時
    //
    handleEventDragStart(info) {
      this.touchedEvent = {
        id: info.event.id,
        title: info.event.title,
        start: moment(info.event.start).format('YYYY-MM-DD HH:mm'),
        end: moment(info.event.end).format('YYYY-MM-DD HH:mm'),
        ...JSON.parse(JSON.stringify(info.event.extendedProps))
      };

      //イベント移動中のツールチップオープン
      this.isEventMoving = true;
    },

    //
    //イベントドラッグストップ時
    //
    handleEventDragStop() {
      this.isEventMoving = false;
    },

    //
    //出勤の無いキャストを非表示
    //
    toggleResourcesWithEvents() {
      this.calOpt.filterResourcesWithEvents = !this.calOpt.filterResourcesWithEvents;

      FCalApi.getEventSourceById(1).refetch();

      // レーン高調整のため再レンダー
      setTimeout(function() { FCalApi.updateSize() }, 100);
    },

    //
    //リソースの並びを切り替え（シフト台帳順<->キャスト台帳順）
    //
    toggleResourceSort() {
      if (this.calOpt.resourceOrder === '-shift_order, is_dummy, -cast_order') {
        this.calOpt.resourceOrder = '-cast_order';
        this.calOpt.views.TimelineDay.resourceAreaColumns[0].headerContent = 'キャスト管理順';
      } else {
        this.calOpt.resourceOrder = '-shift_order, is_dummy, -cast_order';
        this.calOpt.views.TimelineDay.resourceAreaColumns[0].headerContent = '出勤管理順';
      }

      FCalApi.getEventSourceById(1).refetch();

      // レーン高調整のため再レンダー
      setTimeout(function() { FCalApi.updateSize() }, 100);
    },

    //
    //イベント詳細内の予約ステータス変更ボタンクリック時
    //
    openFormBookingStatus(status, fcEvent) {
      const formHanddown = {
        formType: status,
        submitCallback: this.updateBookingState,
        eventData: fcEvent
      };
      this.$refs.formBookingStatus.open(formHanddown);
    },

    //
    //イベント詳細内の会員ボタンから顧客台帳へルート
    //
    routeToCustomerInfo(fcEvent) {
      this.$router.push({
        name: 'shopAdminCustomer',
        query: {
          customer_id: fcEvent.extendedProps.customer_id,
          phone: fcEvent.extendedProps.booker_phone,
          name: fcEvent.extendedProps.booker_name,
        }
      });
    }
  }
};
</script>

<!-- ************************************* -->
<!-- ************** スタイル ************** -->
<!-- ************************************* -->
<style scoped>
.v-picker.v-picker--date {
  z-index: 5;
  position: fixed;
  top: 50px;
  right: 15px;
}
</style>

<style>
.tooltip {
  z-index: 3;
  min-width: 200px;
}
.event-info-pop {
  padding: 1em;
}
.event-info-pop-detail {
  padding-bottom: 0;
}
.event-info-pop-detail p {
  margin-bottom: 5px;
}
.booking-status-btns,
.event-info-pop-btns {
  margin-top: 1em;
  display: flex;
  justify-content: space-around;
}
.booking-status-btns .v-btn.primary,
.event-info-pop-btns .v-btn.primary {
  background-color: var(--v-accent-base) !important;
  font-weight: bold;
}
.event-pop-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
/********** 以下 FCスタリング **********/
.fc .fc-timeline-now-indicator-arrow,
.fc .fc-timegrid-now-indicator-arrow {
  border-top-color: var(--v-accent-base);
}
.fc .fc-timeline-now-indicator-line,
.fc .fc-timegrid-now-indicator-line {
  border: 1px solid var(--v-accent-base);
}
/* セクレトミラー */
.fc .fc-highlight {
  background-color: var(--v-secondary-base);
  opacity: .3;
}
/* リソースヘッダー */
.fc-resource span.cast-name {
  color: grey;
}
.fc-resource.in-cast span {
  color: #222222;
}
.theme--dark .fc-resource.in-cast span {
  color: white;
}
.fc-resource.in-cast.booked span {
  color: var(--v-primary-lighten1);
}
.fc-resource .icon-dummy-cast {
  position: absolute;
  left: 1px;
  width: 23px; height: 23px;
  color: white !important;
  background-color: grey;
  border-radius: 12px;
}
.fc-resource .icon-dummy-cast::before {
  font-family: "Material Design Icons";
  content: "\F0D02";
  line-height: 24px;
  font-size: 20px;
  font-weight: 300;
  color: #444;
}
/* .fc-resource.in-cast.resource-dummy-cast {
  background: rgba(0, 0, 0, 0.1) !important;
} */
.resource-label-injected {
  position: relative;
  overflow: hidden;
}
.resource-label-injected .input-shift {
  width: 50%;
  height: 22px;
  margin-top: 5px;
  text-align: center;
  font-size: 0.8em;
  border: 1px solid #f5f5f5;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
}
.resource-label-injected button {
  margin-bottom: 2px;
  padding: 3px 7px 1px;
  font-size: 0.7em;
  color: #ffffff;
  cursor: pointer;
  border-radius: 3px;
}
.resource-label-injected button.loading {
  cursor: not-allowed;
  cursor: wait;
}
.resource-label-injected .soku-on {
  background-color: var(--v-accent-base);
}
.resource-label-injected .soku-off {
  background-color: var(--grey-lighten);
}
.cast-name {
  font-size: 0.9em;
  line-height: 0.8em;
  word-break: break-word;
}
.resource-label-injected img {
  float: left;
  display: block;
  max-height: 80px;
}
.fc-datagrid-cell.cast-header .fc-datagrid-cell-cushion {
  margin: 0 auto;
  font-size: .8em;
}
.fc-resource-timeline .fc-resource.resource-label-injected {
  font-weight: bold;
  text-align: center;
}
.fc-resource-timeline .fc-datagrid-cell-cushion {
  padding: 1px;
  white-space: normal;
}
.fc-resource-timeline-divider {
  width: 0px !important;
  border: none;
}
/* タイムラインビューイベント */
.custom-event {
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: none !important;
}
.custom-event.event-timeline,
.custom-event.event-grid {
  padding: 2px 3px;
  background: var(--v-primary-base);
  border: none;
}
.event-list p {
  margin: 0;
}
.event-list .booking-name {
  display: inline;
  margin-right: 10px;
}
.fc-timeline-event.custom-event {
  min-width: 57px;
  min-height: 44px;
  position: relative;
  top: 7px;
}
.fc-timeline-event.shift-in {
  height: 15px;
  min-height: 10px !important;
  top: 5px;
}
.fc-timeline-event .v-popover .trigger {
  display: block !important;
}
.custom-event.net {
  background: var(--v-accent-base);
}
.custom-event.line {
  background: rgba(86, 200, 44, 1);
}
.custom-event.dummy {
  background: rgba(0, 0, 0, 0.3);
}
.theme--dark .custom-event.dummy {
  background: rgba(255, 255, 255, 0.3);
}
.custom-event.tentative {
  font-weight: bold;
  opacity: .5;
  border-radius: 7px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5) !important;
}
.theme--dark .custom-event.tentative {
  box-shadow: 1px 3px 3px rgba(255, 255, 255, 0.4) !important;
}
.custom-event.canceled {
  border-radius: 1px;
  background: grey;
}
.custom-event.canceled .event-info {
  color: #aaa;
}
.event-timeline.custom-event.end-unset {
  width: 69px;
}
.event-grid.custom-event.end-unset {
  height: 77px;
  min-height: unset;
}
.custom-event.end-unset {
  background: grey;
}
/* シフトイベント */
.custom-event.shift-in {
  top: 1px;
  border-radius: 0px;
  border: none;
  background: var(--v-primary-base);
  opacity: .5;
}
.custom-event.shift-in .fc-event-main {
  font-size: 0.9em;
  color: #000;
}
.theme--dark .custom-event.shift-in .fc-event-main {
  color: #fff;
}

/* バックグラウンド */
.theme--dark.v-application {
  background: #070707 !important;
}
/* .fc-timeline-body .fc-scrollgrid-sync-table tr {}
.theme--dark .fc-timeline-body .fc-scrollgrid-sync-table tr {} */
.fc-datagrid-body tr:nth-child(odd) .resource-timeline.resource-label-injected,
.fc-timeline-body .fc-scrollgrid-sync-table tr:nth-child(odd) {
  background: #FFFFFF;
}
.theme--dark .fc-datagrid-body tr:nth-child(odd) .resource-timeline.resource-label-injected,
.theme--dark .fc-timeline-body .fc-scrollgrid-sync-table tr:nth-child(odd) {
  background: #131313;
}

/* 以下スロット罫線 */

/* ボーダーサイズ＆カラー */
.theme--dark .fc-theme-standard .fc-scrollgrid,
.theme--dark .fc-scrollgrid-section-body > td:first-of-type,
.theme--dark .fc-scrollgrid-section > td:first-of-type,
.theme--dark .fc-timeline-slot-label {
  border-color: var(--content-border-dark);
}
.fc-datagrid-cell.cast-header,
.theme--light .fc-timeline-slot-label {
  border-bottom: 2px solid #ddd;
}
.theme--dark .fc-timeline-header .fc-scrollgrid-sync-table {
  border-bottom: thin solid;
}
.theme--dark .fc-theme-standard td,
.theme--dark .fc-theme-standard th {
  border-bottom: 1px solid var(--content-border-dark);
}
.fc-timeline-slot-label[data-date*="12:00"],
.fc-timeline-slot-label[data-date*="00:00:00"],
.fc-timeline-slot-label[data-date*="18:00"],
.fc-timeline-slot-lane.lane-sixth-hour.lane-oclock {
  border-left: 3px solid #ddd;
}
.fc-timeline-slot-lane.lane-third-hour.lane-oclock {
  border-left: 2px solid #ddd;
}
.theme--dark .fc-timeline-slot-label[data-date*="12:00"],
.theme--dark .fc-timeline-slot-label[data-date*="00:00:00"],
.theme--dark .fc-timeline-slot-label[data-date*="18:00"],
.theme--dark .fc-timeline-slot-lane.lane-sixth-hour.lane-oclock {
  border-left: 3px solid var(--content-border-dark);
}
.theme--dark .fc-timeline-slot-lane.lane-third-hour.lane-oclock {
  border-left: 2px solid var(--content-border-dark);
}
.theme--dark .fc-timeline-slot-lane {
  border-color: var(--content-border-dark);
}
.fc-timeline-slot-lane.lane-quarter-past,
.fc-timeline-slot-lane.lane-quarter-to {
  border: none;
}
.theme--dark .fc-timeline-slot-lane.lane-half {
  border-color: rgba(120,120,120,.23);
}
</style>
