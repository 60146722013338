<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="800"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="py-5 px-10">
          <v-card-title class="mb-5">
            <h2>{{ formTitle }}</h2>
          </v-card-title>

          <v-form ref="form">
            <!-- キャスト -->
            <v-row>
              <v-col cols="6" class="pb-0">
                <v-select
                  class="mx-2"
                  v-model="castId"
                  label="キャスト"
                  :items="shopData.castArray"
                  item-value="cast_id"
                  item-text="name"
                  item-color="primary"
                  :rules="[valiRules.required]"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="bookingData.is_honshi"
                  inset
                  hide-details
                  label="本指名"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <!-- 開始時刻 -->
              <v-col cols="6"
                class="pb-0"
              >
                <v-menu top
                  v-model="openPickerStart"
                  :close-on-content-click="false"
                  :nudge-left="60"
                  transition="scroll-y-reverse-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startAt"
                      label="開始時刻"
                      prepend-icon="mdi-clock-start"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[valiRules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="startAt"
                    format="24hr"
                    :allowed-hours="shopData.bizHourArray"
                    :allowed-minutes="shopData.bizMinArray"
                    header-color="primary"
                    color="primary"
                    @click:minute="openPickerStart = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <!-- コース未定時の終了時刻 -->
              <v-col cols="6"
                v-if="courseValue.course_id === 0"
                class="pb-0"
              >
                <v-menu top
                  v-model="openPickerEnd"
                  :close-on-content-click="false"
                  :nudge-left="60"
                  transition="scroll-y-reverse-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endAt"
                      label="終了時刻"
                      prepend-icon="mdi-clock-end"
                      readonly
                      hint="コースを選択するなら入力不要"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                      :rules="courseValue.course_id === 0 && [valiRules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="endAt"
                    format="24hr"
                    :allowed-hours="shopData.bizHourArray"
                    :allowed-minutes="shopData.bizMinArray"
                    header-color="primary"
                    color="primary"
                    @click:minute="openPickerEnd = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!-- コースタイプ＆コース -->
            <v-row>
              <v-col cols="6"
                class="pb-0"
              >
                <v-select
                  class="mx-2"
                  v-model="courseTypeValue"
                  label="コースタイプ"
                  :items="shopData.courseTypeArray"
                  return-object
                  item-text="course_type"
                  item-color="primary"
                  required
                  :rules="[valiRules.requiredObj]"
                ></v-select>
              </v-col>
              <v-col cols="6"
                class="pb-0"
              >
                <v-select
                  class="mx-2"
                  v-model="courseValue"
                  label="コース"
                  :items="typeCourses"
                  return-object
                  item-text="course_name"
                  item-color="primary"
                  required
                  :rules="[valiRules.requiredObj]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="pb-0">
                <v-text-field
                  v-model="bookingData.customer_id"
                  type="number"
                  min="1" max="9999"
                  label="会員番号"
                  hint="会員情報が補完されます"
                  persistent-hint
                  :rules="[valiRules.min1num]"
                  :loading="loadingCustomer"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pb-0">
                <v-text-field
                  v-model="bookingData.booker_name"
                  :disabled="bookingData.customer_id !== ''"
                  label="ご予約名"
                  required
                  counter="20"
                  :rules="[valiRules.required, valiRules.max20]"
                  :loading="loadingCustomer"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pb-0">
                <v-text-field
                  v-model="bookingData.booker_phone"
                  :disabled="bookingData.customer_id !== ''"
                  label="電話番号"
                  counter="20"
                  :required="bookingData.booking_type === '電話'"
                  :rules="bookingData.booking_type === '電話' ?
                    [valiRules.required, valiRules.phone, valiRules.max20] : [valiRules.phone, valiRules.max20]"
                  :loading="loadingCustomer"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  class="py-0"
                  v-model="bookingData.place"
                  label="場所"
                  counter="30"
                  :rules="[valiRules.max30]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  class="mt-0 pt-0"
                  v-model="bookingData.sales_ad_id"
                  label="広告媒体"
                  :items="shopData.salesAdArray"
                  item-value="sales_ad_id"
                  item-text="ad_name"
                  item-color="primary"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  class="py-0"
                  v-model="bookingData.note"
                  filled
                  rows="2" no-resize
                  counter="150"
                  label="備考"
                  :rules="[valiRules.max150]"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="2">
                予約種別
              </v-col>
              <v-col cols="10">
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="bookingData.booking_type"
                  mandatory row
                  hide-details
                >
                  <v-radio class="mr-7"
                    v-if="shopData.is_incall"
                    label="来店"
                    value="来店"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="電話"
                    value="電話"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="ダミー"
                    value="ダミー"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="ネット"
                    value="ネット"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="LINE"
                    value="LINE"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="d-flex">
                予約状況
              </v-col>
              <v-col cols="10">
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="bookingData.booking_status"
                  mandatory row
                  hide-details
                >
                  <v-radio class="mr-7"
                    label="確定"
                    value="確定"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="仮予約"
                    value="仮予約"
                  ></v-radio>
                  <v-radio class="mr-7"
                    label="キャンセル"
                    value="キャンセル"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-card-actions class="mt-auto">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                :disabled="!isFormChanged"
                color="primary"
                @click="formSubmitted"
              >
                {{ formButton }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-form>

          <!-- ローダー -->
          <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute top
          color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import moment from 'moment'
import $literals from '@/literals.js'
import { ApiTool, BizHour, CheckTokenError, ConvertPhone, ValidationRules } from '@/module.js'

export default {
  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
    shiftEvents: {
      type: Array,
      required: true
    },
    inputSlotInfo: {
      type: Object
    },
    updateEvent: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      castId: null,
      bookingData: {},
      courseTypeValue: {},
      courseValue: {},
      startAt: '',
      endAt: '',
      openPickerStart: false,
      openPickerEnd: false,
      timerId: null,
      openForm: false,
      isFormChanged: false,
      formType: null,
      loading: false,
      loadingCustomer: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      bizHour: new BizHour(this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    formTitle() {
      return this.formType === 'create' ? '新規予約' : '予約更新'
    },
    formButton() {
      return this.formType === 'create' ? '登録' : '更新'
    },
    isCreate() {
      return this.formType === 'create' ? true : false
    },
    typeCourses() {
      return this.shopData.courseArray.filter( course => course.course_type_id === this.courseTypeValue.course_type_id )
    },
    castName() {
      const castData = this.shopData.castArray.find( cast => cast.cast_id == this.castId )
      return castData !== undefined ? castData.name : ''
    }
  },

  watch: {
    bookingData: {
      handler: function(now, old) {
        if (Object.keys(old).length && now.booking_id === old.booking_id) {
          this.isFormChanged = true
        }
      },
      deep: true
    },
    castId: function() {
      this.isFormChanged = true
    },
    startAt: function() {
      this.isFormChanged = true
    },
    endAt: function() {
      this.isFormChanged = true
    },
    courseValue: function() {
      this.isFormChanged = true
    },
    courseTypeValue: {
      handler: function(now, old) {
        if (!Object.keys(old).length) return
        this.courseValue = {}
        this.isFormChanged = true
      },
      deep: true
    },
    openForm: function(now, old) {
      if (now !== old) this.isFormChanged = false
    },
    //会員番号入力後に名前を自動入力
    'bookingData.customer_id': function(now) {
      if (this.timerId) clearTimeout(this.timerId)
      if (!now || now < 1) return

      this.loadingCustomer = true
      const vm = this
      this.timerId = setTimeout(function() {
        //1秒待って顧客情報取得しに
        vm.adminApi.getReqWithAuth('customer/' + now + '/').then(customerData => {
          vm.bookingData.booker_name = customerData.name || '該当会員無し'
          vm.bookingData.booker_phone = customerData.phone || ''
          vm.bookingData.customer_id = customerData.customer_id || ''
          vm.loadingCustomer = false

          if (customerData.id === 0) alert($literals.MESSAGE.validationCustomerNA)
        })
        .catch(error => CheckTokenError(error))

        vm.timerId = null
      }, 1000)
    },
    'bookingData.booking_type': function(now) {
      if (now === 'ダミー') {
        this.bookingData.booker_name = 'ダミー予約'
        this.bookingData.booker_phone = '07011111111'
      }
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)
  },

  methods: {
    open(formType) {
      this.formType = formType

      if (this.isCreate) {
        this.castId = this.inputSlotInfo.castInfo.cast_id
        this.startAt = moment(this.inputSlotInfo.start.dateTime).format('HH:mm')
        this.endAt = moment(this.inputSlotInfo.end.dateTime).format('HH:mm')
        this.courseTypeValue = this.inputSlotInfo.courseType
        this.courseValue = this.inputSlotInfo.course

        this.bookingData = {
          booking_id: '',
          customer_id: '',
          cast_id: '',
          booking_status: '確定',
          booking_type: '電話',
          start: this.inputSlotInfo.start.dateTime,
          end: this.inputSlotInfo.end.dateTime,
          course_id: 0,
          sales_ad_id: 0,
          is_honshi: false,
          place: '',
          note: '',
          booker_name: '',
          booker_phone: ''
        }
      } else {
        this.bookingData = { ...this.updateEvent }
        if (this.bookingData.customer_id == 0) this.bookingData.customer_id = ''

        this.castId = this.updateEvent.cast_id
        this.courseTypeValue = this.shopData.courseTypeArray.find( course => course.course_type_id == this.bookingData.course_type_id )
        this.courseValue = this.shopData.courseArray.find( course => course.course_id == this.bookingData.course_id )

        this.startAt = moment(this.bookingData.start).format('HH:mm')
        this.endAt = moment(this.bookingData.end).format('HH:mm')
      }
      this.openForm = true
    },

    close() {
      this.courseValue = {}
      this.courseTypeValue = {}
      this.startAt = ''
      this.endAt = ''
      this.openPickerStart = false
      this.openPickerEnd = false
      this.loading = false
      this.isFormChanged = false
      this.openForm = false
    },

    formSubmitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      //時刻の設定
      const bizDate = moment(this.bizHour.getBizOpening(this.bookingData.start)).format('YYYY-MM-DD')
      const start_at = moment(bizDate + ' ' + this.startAt)
      const startH = Number(this.startAt.split(':')[0])
      const endH = Number(this.endAt.split(':')[0])

      //日またぎ営業店考慮（24時以降なら1日追加）
      if (this.bizHour.closingHourNum <= this.bizHour.openingHourNum && startH < this.bizHour.openingHourNum) {
        start_at.add(1, 'd')
      }

      //コース未定で終了時刻入力の場合
      let end_at
      if (this.courseValue.course_id === 0) {
        end_at = moment(bizDate + ' ' + this.endAt)

        if (this.bizHour.closingHourNum <= this.bizHour.openingHourNum && endH < this.bizHour.openingHourNum) {
          end_at.add(1, 'd')
        }
      } else {
        end_at = start_at.clone().add(this.courseValue.course_mins, 'm')
      }

      //バリデーション
      if (start_at.isAfter(end_at)) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationStartEndTime, open: true}}
        return
      }

      //バリデーション：出勤時間外
      const castShift = this.shiftEvents.find(shift => {
        return shift.cast_id === this.castId && start_at.isSameOrAfter(shift.start) && start_at.isSameOrBefore(shift.end)
      })
      if (castShift === undefined) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFcBookingOutOfShiftHour, open: true}}
        return
      }

      this.loading = true
      const title = this.bookingData.booking_type + '予約：' + this.courseValue.course_name
      const eventClass = []
      if (this.bookingData.booking_status === 'キャンセル') eventClass.push('canceled')
      else {
        if (this.bookingData.booking_type === '電話') eventClass.push('phone')
        else if (this.bookingData.booking_type === 'LINE') eventClass.push('line')
        else if (this.bookingData.booking_type === '来店') eventClass.push('incall')
        else if (this.bookingData.booking_type === 'ネット') eventClass.push('net')
        else if (this.bookingData.booking_type === 'ダミー') eventClass.push('dummy')
        if (this.bookingData.booking_status === '仮予約') eventClass.push('tentative')
        if (this.startAt === this.endAt) eventClass.push('end-unset')
      }

      const bookingData = {
        id: this.bookingData.id,
        customer_id: this.bookingData.customer_id || 0,
        cast_id: this.castId,
        booking_type: this.bookingData.booking_type,
        booking_status: this.bookingData.booking_status,
        start: start_at.format('YYYY-MM-DD HH:mm'),
        end: end_at.format('YYYY-MM-DD HH:mm'),
        cast_name: this.castName,
        course_id: this.courseValue.course_id,
        course_name: this.courseValue.course_name,
        course_type_id: this.courseTypeValue.course_type_id,
        course_type: this.courseTypeValue.course_type,
        sales_ad_id: this.bookingData.sales_ad_id,
        is_honshi: this.bookingData.is_honshi,
        place: this.bookingData.place,
        note: this.bookingData.note,
        booker_name: this.bookingData.booker_name,
        booker_phone: ConvertPhone(this.bookingData.booker_phone),
        title: title,
        classNames: eventClass,
        resourceId: this.castId,
        event_type: 'booking',
        start_time: this.startAt,
        end_time: this.endAt
      }

      if (this.isCreate) {
        this.$emit('register-submitted', bookingData)
      } else {
        this.$emit('update-submitted', bookingData)
      }
      this.close()
    },
  }
}
</script>

<style scoped>
>>> .v-time-picker-title {
  justify-content: center;
}
</style>
